import  { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";


import SaveChangesButton from "@/AppComponents/SaveChangesButton";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { toast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchStore, updateStore } from "@/utils/StoreUtils";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Switch } from "@/components/ui/switch";

enum Country {
  DZA = "DZA",
  MAR = "MAR",
  TUN = "TUN",
  EGY = "EGY",
}
enum Store_language {
  AR = "AR",
  EN = "EN",
}

enum Store_category {
  CLOTHES = "CLOTHES",
  ELECTRONICS = "ELECTRONICS",
  GROCERIES = "GROCERIES",
  BOOKS = "BOOKS",
  HOME_DECOR = "HOME_DECOR",
  SPORTS = "SPORTS",
  TOYS = "TOYS",
  BEAUTY = "BEAUTY",
  AUTOMOTIVE = "AUTOMOTIVE",
  JEWELRY = "JEWELRY",
}

export default function General() {
  const [Store, setStore] = useState({});
  const { t } = useTranslation();
  const [imagesToEdit, setimagesToEdit] = useState([]);
  const { data: store, isLoading } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });
  useEffect(() => {
    if (store) {
      setStore(store?.data);
    }
  }, [store]);
  const queryClient = useQueryClient();
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store, imagesToEdit),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  

 
  return (
    <div className=" mx-2 my-4">
      <Card className="w-full">
        <CardHeader>
          
        </CardHeader>
        <CardContent>
          <SaveChangesButton
            btnlabel={t("btns.save")}
            isPending={updateStoreMutation.isPending || isLoading}
            action={() => updateStoreMutation.mutate()}
            label={t("Settings.inside.store.gn")}
          />
          <div  className="w-full">
            
            <div >
              <div className="grid gap-6 md:grid-cols-2">
                {[
                  {
                    id: "store-name",
                    label: t("Settings.inside.store.sn"),
                    value: Store?.name,
                  },
                  {
                    id: "store-title",
                    label: t("Settings.inside.store.st"),
                    value: Store?.title,
                  },
                  {
                    id: "store-Currency",
                    label: t("Settings.inside.store.cr"),
                    value: Store?.Currency,
                  },
                  {
                    id: "store-copyright",
                    label: t("Settings.inside.store.copyright"),
                    value: Store?.copyright,
                  },
                ].map((field) => (
                  <div key={field.id} className="space-y-2">
                    <Label htmlFor={field.id}>{field.label}</Label>
                    <Input
                      id={field.id}
                      value={field.value}
                      onChange={(e) =>
                        setStore({
                          ...Store,
                          [field.id.replace("store-", "")]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
                <div className="space-y-2">
                  <Label htmlFor="store-country"> 
                    {t("random.country")}
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setStore({ ...Store, Country: value })
                    }
                    defaultValue={Store?.Country}>
                    <SelectTrigger id="store-country">
                      <SelectValue placeholder={Store?.Country} />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(Country).map((country) => (
                        <SelectItem key={country} value={country}>
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="store-category">
                    {t("random.category")}
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setStore({ ...Store, Store_category: value })
                    }
                    defaultValue={Store?.Store_category}>
                    <SelectTrigger id="store-category">
                      <SelectValue placeholder={Store?.Store_category} />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(Store_category).map((category) => (
                        <SelectItem key={category} value={category}>
                          {category.replace(/_/g, " ")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="store-category">
                    {t("random.language")}
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setStore({ ...Store,Language:value })
                    }
                    >
                    <SelectTrigger id="store-category">
                      <SelectValue placeholder={t("random.language")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(Store_language).map((language) => (
                        <SelectItem key={language} value={language}>
                          {language.replace(/_/g, " ")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <Card className="p-4 mt-5">
                <div className="row flex justify-between">
                  <div className="col-12 col-sm-6 d-flex flex-column space-y-4">
                    <label
                      
                      htmlFor="basic-url"
                      >
                       {t("random.main")}
                    </label>
                    <div className="flex gap-4  ">
                      <Input
                        placeholder="Enter color code"
                        type="text"
                        className="w-full inline-flex items-center justify-between ssb2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control"
                        defaultValue={Store?.primaryColor}
                        onChange={(e)=>setStore({...Store,primaryColor:e.target.value})}
                        
                      />
                     
                      <input
                        type="color"
                        className="w-full  inline-flex items-center ssb justify-between  text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control form-control-color"
                        defaultValue={Store?.primaryColor}
                        onChange={(e)=>setStore({...Store,primaryColor:e.target.value})}
                        
                      />
                       <div style={{backgroundColor:Store?.primaryColor}} className="p-4 rounded-full"/>
                    </div>
                    <label
                      
                      htmlFor="basic-url"
                      >
                                             {t("random.secondary")}
                    </label>
                    <div className="mb-3 flex gap-4">
                      <Input
                        placeholder="Enter color code"
                        type="text"
                        className="w-full inline-flex items-center justify-between ssb2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control"
                        value={Store?.secondaryColor}
                        onChange={(e)=>setStore({...Store,secondaryColor:e.target.value})}
                      />
                      <input
                        type="color"
                        className="w-full inline-flex items-center ssb justify-between  text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control form-control-color"
                        defaultValue={Store?.secondaryColor}
                        onChange={(e)=>setStore({...Store,secondaryColor:e.target.value})}
                        
                      />
                       <div style={{backgroundColor:Store?.secondaryColor}} className="p-4 rounded-full"/>

                    </div>
                  </div>
                  <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                    <svg
                      width={180}
                      height={239}
                      viewBox="0 0 180 239"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="1.56572"
                        y="1.38158"
                        width="176.869"
                        height="236.237"
                        rx="10.5132"
                        fill="#F3F3F3"
                        stroke="#E9E9E9"
                        strokeWidth="1.23684"
                      />
                      <rect
                        x="3.1052"
                        y="2.1052"
                        width="172.7393"
                        height="6.18421"
                        rx="3.09211"
                        fill={Store?.primaryColor}
                      />
                      <rect
                        x="78.184"
                        y="13.6055"
                        width="24.7369"
                        height="8.6579"
                        rx="4.32895"
                        fill="#C4C4C4"
                      />
                      <rect
                        x="10.842"
                        y="194.948"
                        width="18.7393"
                        height="6.18421"
                        rx="3.09211"
                        fill={Store?.primaryColor}
                      />
                      <rect
                        x="30.6315"
                        y="194.948"
                        width="18.7393"
                        height="6.18421"
                        rx="3.09211"
                        fill={Store?.secondaryColor}
                      />
                      <rect
                        x="64.8421"
                        y="28.3159"
                        width="51.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.primaryColor}
                      />
                      <rect
                        x="134.1578"
                        y="177.3159"
                        width="11.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.secondaryColor}
                      />
                      <rect
                        x="83.1578"
                        y="177.3159"
                        width="11.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.secondaryColor}
                      />
                      <rect
                        x="30.1578"
                        y="177.3159"
                        width="11.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.secondaryColor}
                      />
                      <circle
                        cx="161.6767"
                        cy="225.5525"
                        r="2.47369"
                        fill="#C4C4C4"
                      />
                      <circle
                        cx="154.6767"
                        cy="225.5525"
                        r="2.47369"
                        fill="#C4C4C4"
                      />
                      <rect
                        x="10.842"
                        y="35.3948"
                        width="157.079"
                        height="81.6316"
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <rect
                        x="80.1052"
                        y="227.105"
                        width="18.5526"
                        height="2.47369"
                        fill="#E9E9E9"
                      />
                      <rect
                        x="11.2368"
                        y="121.974"
                        width={50}
                        height={63}
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <rect
                        x="118.2368"
                        y="121.974"
                        width={50}
                        height={63}
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <rect
                        x="65.2368"
                        y="121.974"
                        width={50}
                        height={63}
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 97.0837 111.179)"
                        fill="#C4C4C4"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 92.3612 111.179)"
                        fill="#C4C4C4"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 87.6387 111.179)"
                        fill="#ffffff"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 82.9162 111.179)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </div>
                </div>
              </Card>

            </div>
            <div className="pt-4">
              <Card className="p-4 flex items-center justify-between">
                <h1>Activate Shopping Cart</h1>
                <Switch onCheckedChange={(checked)=>setStore({...Store,ShoppingCart:checked})}  checked={Store?.ShoppingCart}/>
              </Card>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
