import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import SaveChangesButton from "@/AppComponents/SaveChangesButton";

import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";

import { fetchStore, updateStore } from "@/utils/StoreUtils";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

export default function Policies() {
  const [Store, setStore] = useState({});
  const { t } = useTranslation();
  const [imagesToEdit, setimagesToEdit] = useState([]);
  const { data: store, isLoading } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });
  useEffect(() => {
    if (store) {
      setStore(store?.data);
    }
  }, [store]);
  const queryClient = useQueryClient();
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store, imagesToEdit),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  return (
    <div className=" mx-2 my-4">
      <Card className="w-full">
        <CardHeader></CardHeader>
        <CardContent>
          <SaveChangesButton
            btnlabel={t("btns.save")}
            isPending={updateStoreMutation.isPending || isLoading}
            action={() => updateStoreMutation.mutate()}
            label={t("Settings.inside.store.policies")}
          />
          <div className="grid gap-6">
            {[
              {
                id: "store-return_agreements",
                label: t("Settings.inside.store.return"),
                value: Store?.return_agreements,
              },
              {
                id: "store-paying_agreements",
                label: t("Settings.inside.store.payment"),
                value: Store?.paying_agreements,
              },
              {
                id: "store-thank_you_page",
                label: t("Settings.inside.store.thanks"),
                value: Store?.thank_you_page,
              },
            ].map((field) => (
              <div key={field.id} className="space-y-2">
                <Label htmlFor={field.id}>{field.label}</Label>
                <Textarea
                  id={field.id}
                  value={field.value}
                  onChange={(e) =>
                    setStore({
                      ...Store,
                      [field.id.replace("store-", "")]: e.target.value,
                    })
                  }
                />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
