import ActionFooter from "@/AppComponents/ActionFooter";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import ReactQuill from "react-quill";

import {
  Car,
  Check,
  Delete,
  Image,
  Plus,
  Save,
  Trash,
  Upload,
  Video,
  X,
} from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Link } from "react-router-dom";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/components/ui/use-toast";
import { addProduct } from "@/utils/ProductUtils";

import { fetchCategories } from "@/utils/CategoryUtils";
import { Label } from "@/components/ui/label";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";
import { uploadUrl } from "@/utils/StoreUtils";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import { StoreContext } from "@/utils/StoreContext";
import { Badge } from "@/components/ui/badge";
import hasAccess from "@/utils/access/hasAccess";

export default function AddProduct() {
  const [variantOptions, setvariantOptions] = useState([]);
  const [offers, setOffers] = useState([]);
  const [tempvariant, setVariant] = useState("");
  const [tempvariants, settempVariant] = useState("");
  const [tempPrice,settempPrice]=useState(0)
  const [showValidation, setShowValidation] = useState(false);
  const quillRef = useRef(null);

  const { data: categories, isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchCategories(1, 50),
  });
  const handleImageUpload = async (e) => {
    setDescriptionLoading(true);
    const file = e.target.files?.[0];
    // Replace with your server upload endpoint
    const response = await uploadUrl(file);

    // Assuming the response contains the URL of the uploaded image
    try {
      const quill = quillRef.current.getEditor();

      const range = quill.getSelection();
      quill.insertEmbed(range?.index, "image", response.url);
      setDescriptionLoading(false);
    } catch (error) {
      setDescriptionLoading(false);
      console.error("Error inserting image:", error);
    }
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "4" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "video"],

      ["clean"],
    ],
  };
  const [product, setProduct] = useState({
    name: "",
    description: "",
    isVisible: true,
    categoryId: null,
    price: 0,
    freeshiping: false,
    oldprice: 0,
    HomePage: true,
    useInventory: false,
    ShowDeskDelivery: false,
    require_client_email: false,
  });
  const queryClient = useQueryClient();
  const addProductMutation = useMutation({
    mutationFn: () =>
      addProduct(product, variantOptions, offers, images, mainImageIndex),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      setProduct({
        ...product,
        name: "",
        description: "",
        isVisible: true,
        price: 0,
        freeshiping: false,
        oldprice: 0,
        useInventory: false,
        ShowDeskDelivery: false,
        require_client_email: false,
      });
      setvariantOptions([]);
      setOffers([]);
      setImages([]); // Clear images after successful upload
      setMainImageIndex(null);
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      addProductMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });
  // useEffect(() => {
  //   window.scrollTo({
  //     top:
  //       window.innerHeight + variantOptions.length * 100 + offers.length * 50,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, [variantOptions, offers]);
  const handleAddProduct = () => {
    setShowValidation(true);
    if (
      product.name === "" ||
      product.price == null ||
      product.categoryId == null
    ) {
      toast({
        title: t("alert.err_title"),
        description: t("alert.err_d"),
        variant: "destructive",
      });
    } else {
      addProductMutation.mutate();
    }
  };

  const { t } = useTranslation();

  {
    /*
      image uploading section
    */
  }
  const [images, setImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState<number | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [descriptionLoading, setDescriptionLoading] = useState(false);
  const [OverallLoading, setOverallLoading] = useState(false);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverallLoading(true);
    if (e.target.files) {
      const newImages = Array.from(e.target.files).map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      setImages((prevImages) => [...prevImages, ...newImages]);
      if (mainImageIndex === null && newImages.length > 0) {
        setMainImageIndex(0);
      }
      setOverallLoading(false);
    }
    setOverallLoading(false);
  };

  const removeImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    if (mainImageIndex === index) {
      setMainImageIndex(images.length > 1 ? 0 : null);
    } else if (mainImageIndex !== null && index < mainImageIndex) {
      setMainImageIndex(mainImageIndex - 1);
    }
  };

  const { storeData: store } = useContext(StoreContext);
  //if(!hasAccess(store?.user?.subscriptions[0]?.plan,{action:"addProducts",limit:store?._count.products})) return <h1>you reached the limit</h1>
  return (
    <>
      
      <div className="flex flex-col ">
        <h1 className="text-xl p-4">{t("Products.inside.create.title")}</h1>
        <div className="flex gap-2 p-2">
          <div className="flex flex-col flex-1 gap-1">
            <Card className="p-4">
              <Input
                value={product.name}
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
                className=""
                placeholder={t("Products.inside.create.name")}
              />
            </Card>
            <Card>
              <div className=" p-4 space-y-2 ">
                <h1 className="font-bold  text-xl p-2">
                  {t("Products.inside.create.im")}
                </h1>

                <div className="max-w-4xl mx-auto p-4">
                  <div className="mb-4">
                    <Input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept="image/*, video/*"
                      multiple
                      className="hidden"
                      id="image-up"
                    />
                    {!OverallLoading ? (
                      <Label htmlFor="image-up" className="cursor-pointer">
                        <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-gray-400 transition-colors">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <p className="mt-2 text-sm text-gray-600">
                            {t("Products.inside.create.uib")}
                          </p>
                        </div>
                      </Label>
                    ) : (
                      <LoaderCircle />
                    )}
                  </div>
                  {images.length > 0 && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
                      {images.map((image, index) => (
                        <div key={index} className="relative group">
                          <img
                            src={image.preview}
                            alt={`Uploaded image ${index + 1}`}
                            width={200}
                            height={200}
                            className={cn(
                              "w-full h-40 object-cover rounded-lg",
                              mainImageIndex === index &&
                                "ring-2 ring-primary ring-offset-2"
                            )}
                          />
                          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => setMainImageIndex(index)}
                              className="mr-2">
                              Set as Main
                            </Button>
                            <Button
                              variant="destructive"
                              size="icon"
                              onClick={() => removeImage(index)}>
                              <X className="h-4 w-4" />
                            </Button>
                          </div>
                          {mainImageIndex === index && (
                            <span className="absolute top-2 left-2 bg-primary text-primary-foreground text-xs px-2 py-1 rounded-full">
                              Main
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Card>
            <Card className="space-y-2 p-4">
              <h1 className="p-2 font-bold text-xl">
                {t("Products.inside.create.pri")}{" "}
              </h1>
              <span className="text-sm text-muted-foreground p-2">
                {t("Products.inside.create.ops")}
              </span>

              <div className="flex flex-col lg:flex-row gap-2 lg:items-center lg:justify-between">
                <div className="flex gap-4 items-center ">
                  <Label>{t("Products.inside.create.pr")} </Label>

                  <Input
                    value={product.price}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        price: e.target.valueAsNumber,
                      })
                    }
                    className=" "
                    type="number"
                    placeholder="Price"
                  />
                  {store?.Currency}
                </div>
                {/* <div className="flex  items-center "> */}
                {/*   <Label>{t("Products.inside.create.opr")} </Label> */}
                {/*   <Input */}
                {/*     value={product.oldprice} */}
                {/*     onChange={(e) => */}
                {/*       setProduct({ */}
                {/*         ...product, */}
                {/*         oldprice: e.target.valueAsNumber, */}
                {/*       }) */}
                {/*     } */}
                {/*     className=" " */}
                {/*     type="number" */}
                {/*     placeholder="Price" */}
                {/*   /> */}
                {/* </div> */}
              </div>

              <div className="flex items-center gap-2 pt-2">
                <Checkbox
                  checked={product?.ShowDeskDelivery}
                  onCheckedChange={(checked) =>
                    setProduct({ ...product, ShowDeskDelivery: checked })
                  }
                  className="w-6 h-6"
                />
                <p className="font-bold text-gray-500">
                {t("Products.inside.create.show_desk_delivery")}
                </p>
              </div>
              <div className="flex items-center gap-2 pt-2">
                <Checkbox
                  checked={product?.useInventory}
                  onCheckedChange={(checked) =>
                    setProduct({ ...product, useInventory: checked })
                  }
                  className="w-6 h-6"
                />
                <p className="font-bold text-gray-500">
                {t("Products.inside.create.use_inventory")}
                </p>
              </div>
            </Card>
            <Card className="p-4 grid grid-cols-1 sm:grid-cols-1 gap-4">
              <Button
                onClick={() =>
                  setProduct({ ...product, freeshiping: !product.freeshiping })
                }
                variant="secondary"
                className={`${
                  product.freeshiping ? "ring ring-blue-200" : ""
                } `}>
                <p className="text-muted-foreground">
                  {t("Products.inside.create.fs")}
                </p>
              </Button>
              <Button
                onClick={() =>
                  setProduct({ ...product, HomePage: !product.HomePage })
                }
                variant="secondary"
                className={`${product.HomePage ? "ring ring-blue-200" : ""} `}>
                <p className="text-muted-foreground">
                  {t("Products.inside.create.sahs")}
                </p>
              </Button>

              <Button
                onClick={() =>
                  setProduct({
                    ...product,
                    require_client_email: !product.require_client_email,
                  })
                }
                variant="secondary"
                className={`${
                  product.require_client_email ? "ring ring-blue-200" : ""
                } `}>
                <p className="text-muted-foreground">
                  {t("Products.inside.create.rqe")}
                </p>
              </Button>
              <Button
                onClick={() =>
                  setProduct({ ...product, isVisible: !product.isVisible })
                }
                variant="secondary"
                className={`${
                  product.isVisible ? "ring ring-blue-200" : ""
                }  `}>
                <p className="font-bold text-gray-500">
                  {t("Products.inside.create.os")}
                </p>
              </Button>
            </Card>
            <div className=" flex flex-col gap-2 lg:hidden  ">
              <Card className="p-4 space-y-2">
                <h1 className="text-xl">
                  {t("Products.inside.create.ct")}{" "}
                  <span className="text-red-500">*</span>
                </h1>

                <Select
                  onValueChange={(value) =>
                    setProduct({ ...product, categoryId: value })
                  }>
                  <SelectTrigger
                    className={
                      showValidation && !product.categoryId
                        ? "border-red-500"
                        : ""
                    }>
                    <SelectValue placeholder={t("Products.inside.create.sc")} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>
                        {t("Products.inside.create.cts")}{" "}
                      </SelectLabel>

                      {categories &&
                        categories?.data?.categories?.map((category, index) => (
                          <SelectItem key={category.id} value={category.id}>
                            {category.name}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {showValidation && !product.categoryId && (
                  <p className="text-red-500 text-sm">Category is required</p>
                )}
                <Link
                  to="/dashboard/categories/new"
                  className={buttonVariants({ variant: "link" })}>
                  {t("Products.inside.create.ac")}
                </Link>
              </Card>
            </div>
            <Card className="p-4">
              <h1 className="font-bold  text-xl p-2 mb-1">
              {t("Products.inside.create.ds")}
                
              </h1>

              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={product.description}
                defaultValue={"type something ..."}
                onChange={(value) =>
                  setProduct({ ...product, description: value })
                }
                modules={modules}
              />
              <div className="my-2">
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e)}
                  className="p-2 my-2 hidden text-accent cursor-pointer hover:ring"
                  id="image-upload"
                />
                {!descriptionLoading ? (
                  <Label htmlFor="image-upload" className="cursor-pointer">
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-gray-400 transition-colors">
                      <Upload className="mx-auto h-12 w-12 text-gray-400" />
                      <p className="mt-2 text-sm text-gray-600">
              {t("Products.inside.create.uib")}
                        
                      </p>
                    </div>
                  </Label>
                ) : (
                  <LoaderCircle />
                )}
              </div>
            </Card>
            <Card className="space-y-2 p-4">
              <h1 className="p-2 font-bold text-xl">
                {t("Products.inside.create.vr")}{" "}
              </h1>
              <span className="text-xs p-2 text-muted-foreground">
                {t("Products.inside.create.option_d")}
              </span>
              <div className="space-y-2">
                <Button
                  onClick={() =>
                    setvariantOptions((val) => [
                      ...val,
                      { option: "", value: [] },
                    ])
                  }
                  size="lg"
                  className="w-full">
                  {t("Products.inside.create.ao")}
                </Button>
                {variantOptions?.map((variant, index) => (
                  <div key={index} className="flex flex-col border rounded-lg">
                    <div className="flex p-2">
                      <Checkbox
                        value={variant.usePrice}
                        onCheckedChange={(checked) => {
                          variant.usePrice = checked;
                          setvariantOptions((val) => [...val]);
                        }}
                        className=""
                      />
                      <p className="px-4 text-muted-foreground">
                      {t("Products.inside.create.use_prices")}
                      </p>
                    </div>
                    <div className="flex w-full  flex-row   justify-start ">
                      <div className="flex flex-1 flex-col items-start p-2 gap-1">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.opt")}
                        </p>

                        <Input
                          value={variant.option}
                          onChange={(e) => {
                            variant.option = e.target.value;
                            setvariantOptions((val) => [...val]);
                          }}
                          className=""
                          placeholder={t("Products.inside.create.opt_items")}
                        />
                      </div>
                      <div className="flex flex-1 flex-col items-start p-2 gap-1">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.type")}
                        </p>

                        <Select
                          onValueChange={(value) => {
                            variant.type = value;
                            setvariantOptions((val) => [...val]);
                          }}>
                          <SelectTrigger className="">
                            <SelectValue
                              placeholder={t("Products.inside.create.type")}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>
                                {t("Products.inside.create.type")}
                              </SelectLabel>
                              <SelectItem value="color">
                                {t("Products.inside.create.type_one")}
                              </SelectItem>
                              <SelectItem value="other">
                                {t("Products.inside.create.type_two")}
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-1 flex-col  p-2 gap-1">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.value")}
                        </p>
                        {variant.type == "color" ? (
                          <div className="flex flex-row ">
                            <input
                              className="mx-2  p-0"
                              onChange={(e) => setVariant(e.target.value)}
                              type="color"
                            />
                            <Input
                              onChange={(e) => settempVariant(e.target.value)}
                              placeholder={t("Products.inside.create.cname")}
                            />
                            
                          </div>
                        ) : (
                          <Input
                            value={tempvariant}
                            onChange={(e) => setVariant(e.target.value)}
                            className=""
                            placeholder={t(
                              "Products.inside.create.value_items"
                            )}
                          />
                        )}
                        {variant.usePrice ? (
                                <Input
                                value={tempPrice}
                                type="number"
                            onChange={(e) => settempPrice(e.target.valueAsNumber)}
                                placeholder="price" />
                              ) : (
                                ""
                              )}
                      </div>
                      <Button
                        onClick={() => {
                          if (tempvariant == "" || variant.option == "") {
                            toast({
                              description:
                                t("Products.inside.create.add_v_error"),
                              variant: "destructive",
                            });
                          } else {
                            if (variant.type == "color" ) {
                              if(variant.usePrice){
                                variant.value.push({
                                  value: tempvariant,
                                  name: tempvariants,
                                  price:tempPrice
                                });
                              }else{
                                variant.value.push({
                                  value: tempvariant,
                                  name: tempvariants,
                                });
                              }
                              
                            } else{
                              if(variant.usePrice){
                              variant.value.push({
                                name:tempvariant,
                                price:tempPrice
                              });

                              }else{
                                variant.value.push({
                                  name:tempvariant
                                })
                              }
                      
                            }
                            setvariantOptions((val) => [...val]);
                            setVariant("");
                          }
                        }}
                        className="self-end mb-2"
                        size="icon"
                        variant={"ghost"}>
                        <Plus />
                      </Button>
                      <Button
                        className="self-end mb-2"
                        onClick={() =>
                          setvariantOptions(
                            variantOptions.filter((item) => item !== variant)
                          )
                        }
                        size={"icon"}
                        variant={"ghost"}>
                        <Trash />
                      </Button>
                    </div>
                    <div className="flex gap-2 p-2 relative">
                      {variant.value.map((val, i) => (
                        <div
                          key={i}
                          className="flex bg-muted  items-center gap-4  p-2 rounded-xl">
                          {variant.type == "color" ? (
                            <div className="">
                              <div className="flex items-center ">
                                <div
                                  className="w-8 h-8 ring rounded-full "
                                  style={{ backgroundColor: val.value }}></div>
                                <p className="px-2">{val.name}</p>
                              </div>
                              {variant.usePrice ? (
                               <Badge className="absolute bottom-0 place-self-start">{val.price}</Badge>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                           <div className="">
                            <h1>{val.name}</h1>
                            {variant.usePrice ? (
                               <Badge className="absolute bottom-0 place-self-start">{val.price}</Badge>
                              ) : (
                                ""
                              )}
                           </div>
                          )}
                          <Button
                            onClick={() => {
                              const item = variant.value.find(
                                (toremove) => toremove === val
                              );
                              const index = variant.value.indexOf(item);
                              variant.value.splice(index, 1);

                              setvariantOptions((val) => [...val]);
                            }}
                            variant="ghost"
                            size="icon"
                            className="w-5">
                            <Trash />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
            <Card className="p-4 space-y-2">
              <h1 className="p-2 font-bold text-xl">
                {t("Products.inside.create.of")}{" "}
              </h1>

              <div>
                <Button
                  onClick={() =>
                    setOffers((val) => [
                      ...val,
                      { title: "", quantity: 0, price: 0 },
                    ])
                  }
                  size="lg"
                  className="w-full">
                  {t("Products.inside.create.aof")}
                </Button>
              </div>
              <div className="">
                {offers.map((offer, index) => (
                  <div key={index} className="flex gap-2 p-2 items-end">
                    <div className="flex flex-col items-start ">
                      <p className="text-sm p-2">
                        {t("Products.inside.create.o_d")}
                      </p>
                      <Input
                        value={offer.title}
                        onChange={(e) => {
                          offer.title = e.target.value;
                          setOffers((val) => [...val]);
                        }}
                        placeholder={t("Products.inside.create.o_d_d")}
                      />
                    </div>
                    <div className="flex flex-col items-start ">
                      <p className="text-sm p-2">
                        {t("Products.inside.create.o_q")}
                      </p>
                      <Input
                        type="number"
                        value={offer.quantity}
                        onChange={(e) => {
                          offer.quantity = e.target.valueAsNumber;
                          setOffers((val) => [...val]);
                        }}
                        placeholder="quantity"
                      />
                    </div>
                    <div className="flex flex-col items-start ">
                      <p className="text-sm p-2">
                        {t("Products.inside.create.otp")}{" "}
                      </p>
                      <Input
                        value={offer.price}
                        type="number"
                        onChange={(e) => {
                          offer.price = e.target.valueAsNumber;
                          setOffers((val) => [...val]);
                        }}
                        placeholder="price"
                      />
                    </div>
                    <Button
                      onClick={() => {
                        setOffers(
                          offers.filter((item) => item.title !== offer.title)
                        );
                      }}
                      variant="ghost"
                      size="icon">
                      <Trash className="w-5" />
                    </Button>
                  </div>
                ))}
              </div>
            </Card>
          </div>
          <div className=" hidden lg:flex flex-col w-1/3 gap-2 ">
            <Card className="p-4 space-y-2">
              <h1 className="text-xl">
                {t("Products.inside.create.ct")}{" "}
                <span className="text-red-500">*</span>{" "}
              </h1>

              <Select
                onValueChange={(value) =>
                  setProduct({ ...product, categoryId: value })
                }>
                <SelectTrigger
                  className={
                    showValidation && !product.categoryId
                      ? "border-red-500"
                      : ""
                  }>
                  <SelectValue placeholder={t("Products.inside.create.cts")} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>
                      {t("Products.inside.create.cts")}{" "}
                    </SelectLabel>

                    {categories &&
                      categories?.data?.categories?.map((category, index) => (
                        <SelectItem key={category.id} value={category.id}>
                          {category.name}
                        </SelectItem>
                      ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              {showValidation && !product.categoryId && (
                <p className="text-red-500 text-sm">Category is required</p>
              )}
              <Link
                to="/dashboard/categories/new"
                className={buttonVariants({ variant: "link" })}>
                {t("Products.inside.create.ac")}
              </Link>
            </Card>
          </div>
        </div>
      </div>
      <ActionFooter
        icon={<Save />}
        text={t("Products.inside.create.title")}
        action={handleAddProduct}
        isPending={addProductMutation.isPending}
      />
    </>
  );
}
