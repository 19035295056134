"use client";

import * as React from "react";
import {
  Banknote,
  Blocks,
  Box,
  Caravan,
  Frame,
  GalleryVerticalEnd,
  Layers,
  LayoutGrid,
  LogOut,
  Map,
  MessageCircleQuestion,
  PackageSearch,
  PieChart,
  Plus,
  SettingsIcon,
  Share2,
  Store,
  Ticket,
  Users,
} from "lucide-react";

import { NavMain } from "@/components/nav-main";
import { NavProjects } from "@/components/nav-projects";
import { NavUser } from "@/components/nav-user";
import { TeamSwitcher } from "@/components/team-switcher";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "@/components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { useHref, useNavigate } from "react-router-dom";
import { StoreContext } from "@/utils/StoreContext";
import { toast } from "./ui/use-toast";
import Axios from "@/utils/Axios";
import { Button } from "./ui/button";
import LoaderCircle from "@/AppComponents/LoaderCircle";

// This is sample data.

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const pathname = useHref();
  const { storeData: store } = React.useContext(StoreContext);
  const data = {
    user: {
      name: store?.user?.fullname,
      email: store?.user?.email,
      avatar: "/icon.png",
    },
    teams: [
      {
        name: "Keeprify",
        logo: GalleryVerticalEnd,
        plan: "Enterprise",
      },
    ],
    navMain: [
      {
        url: "/dashboard/",
        title: t("Dashboard.title"),
        isActive: pathname === "/dashboard" || pathname === "/dashboard/",
        icon: LayoutGrid,
        items: [],
      },
      {
        url: "/dashboard/orders",
        title: t("Orders.title"),
        isActive: pathname.includes("/dashboard/orders"),
        icon: Box,
        items: [
          {
            url: "/dashboard/orders",
            title: t("Orders.sublinks.allorders"),
            isActive: pathname === "/posts",
          },
          {
            url: "/dashboard/orders/new",
            title: t("Orders.sublinks.neworders"),
            isActive: pathname === "/orders/new",
          },
        ],
      },
      {
        url: "/dashboard/clients",
        title: t("Customers.title"),
        isActive: pathname.includes("/dashboard/clients"),
        icon: Users,
        items: [
          {
            url: "/dashboard/clients",
            isActive: pathname === "dashboard/clients",
            title: t("Customers.sublinks.all"),
          },
          {
            url: "/dashboard/clients/new",
            isActive: pathname === "dashboard/clients/new",
            title: t("Customers.sublinks.add"),
          },
        ],
      },
      {
        url: "/dashboard/products",
        title: t("Products.title"),
        isActive: pathname.includes("/products"),
        icon: PackageSearch,
        items: [
          {
            url: "/dashboard/products",
            title: t("Products.sublinks.allproducts"),
            isActive: pathname === "/products",
          },
          {
            url: "/dashboard/products/new",
            title: t("Products.sublinks.newproducts"),
            isActive: pathname === "/products/new",
          },
          {
            url: "/dashboard/categories",
            title: t("Products.sublinks.categories"),
            isActive: pathname === "/dashboard/categories",
          },
          {
            url: "/dashboard/products/inventory",
            title: t("Products.sublinks.ms"),
            isActive: pathname === "dashboard/products/inventory",
          },
        ],
      },
      {
        url: "/dashboard/coupons",
        title: t("Coupons.title"),
        isActive: pathname.includes("/dashboard/coupons"),
        icon: Ticket,
        items: [
          {
            url: "/dashboard/coupons",
            isActive: pathname === "dashboard/coupons",
            title: t("Coupons.sublinks.allcoupons"),
          },
          {
            url: "/dashboard/coupons/new",
            isActive: pathname === "dashboard/coupons/new",
            title: t("Coupons.sublinks.newcoupons"),
          },
        ],
      },
      {
        url: "/dashboard/store",
        title: t("Store.title"),
        isActive: pathname.includes("/dashboard/store"),
        icon: Store,
        items: [
          {
            url: "/dashboard/store",
            isActive: pathname === "dashboard/customize",
            title: t("Settings.inside.store.gn"),
          },
          {
            url: "/dashboard/store/contact",
            isActive: pathname === "dashboard/customize",
            title: t("Settings.inside.store.cn"),
          },
          {
            url: "/dashboard/store/policies",
            isActive: pathname === "dashboard/customize",
            title: t("Settings.inside.store.policies"),
          },
          {
            url: "/dashboard/store/media",
            isActive: pathname === "dashboard/customize",
            title: t("Settings.inside.store.Media"),
          },
        ],
      },
      // {
      //   url: "/dashboard/landingpages",
      //   title: t("landing.title"),
      //   isActive: pathname.includes("/dashboard/landingpages"),
      //   icon: Layers,
      //   items: [],
      // },
      {
        url: "/dashboard/settings/account",
        title: t("Settings.title"),
        isActive: pathname.includes("/dashboard/settings"),
        icon: SettingsIcon,
        items: [],
      },
      {
        url: "/dashboard/ship",
        title: t("Shipping.title"),
        isActive: pathname.includes("/dashboard/ship"),
        icon: Caravan,
        items: [],
      },
      {
        url: "/dashboard/plugins/telegram_notifications",
        title: t("Plugins.title"),
        isActive: pathname.includes("/dashboard/plugins"),
        icon: Blocks,
        items: [],
      },
      {
        url: "/dashboard/payment",
        title: t("Payment.title"),
        isActive: pathname.includes("/dashboard/payment"),
        icon: Banknote,
        items: [],
      },
      {
        url: "/dashboard/support",
        title: t("support.title"),
        isActive: pathname.includes("/dashboard/support"),
        icon: MessageCircleQuestion,
        items: [],
      },
      {
        url: "/dashboard/affiliate",
        title: t("affiliate.title"),
        isActive: pathname.includes("/dashboard/affiliate"),
        icon: Share2,
        items: [],
      },
    ],
    projects: [
      {
        name: "Design Engineering",
        url: "#",
        icon: Frame,
      },
      {
        name: "Sales & Marketing",
        url: "#",
        icon: PieChart,
      },
      {
        name: "Travel",
        url: "#",
        icon: Map,
      },
    ],
  };
  const navigate=useNavigate()
  const [isLoading, setIsLoading] = React.useState(false);

  const SignOutHandler = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get("/auth/logout");
      if (response.data.success) {
        navigate("/login");
        toast({
          title: "Logged Out successfully",
          description: "Redirecting to Login page...",
        });
      } else {
        throw new Error("Logout unsuccessful");
      }
    } catch (err) {
      console.error("Error:", err);
      toast({
        title: "LogOut unsuccessful",
        description: "Try Again",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [navigate, toast]);
 
  return (
    <>
      <Sidebar
        className={language == "ar" ? "font-ar" : "font-en"}
        
        side={language == "ar" ? "right" : "left"}
        collapsible="icon"
        {...props}
      >
        <SidebarHeader>
          <NavUser user={data.user}/>
        </SidebarHeader>
        <SidebarContent>
          <NavMain items={data.navMain} />
        </SidebarContent>
        <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton onClick={()=>SignOutHandler()}>
             {isLoading?<LoaderCircle/>:<>
              <LogOut />
              <span>{t("Menu.LogOut")}</span>
             </>}
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
    </>
  );
}
