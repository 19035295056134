import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import SaveChangesButton from "@/AppComponents/SaveChangesButton";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { toast } from "@/components/ui/use-toast";

import { fetchStore, updateStore } from "@/utils/StoreUtils";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

export default function Media() {
  const [Store, setStore] = useState({});
  const { t } = useTranslation();
  const [imagesToEdit, setimagesToEdit] = useState([]);
  const { data: store, isLoading } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });
  useEffect(() => {
    if (store) {
      setStore(store?.data);
    }
  }, [store]);
  const queryClient = useQueryClient();
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store, imagesToEdit),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, field) => {
    if (e.target.files) {
      const newImage = Object.assign(e.target.files?.[0], {
        preview: URL.createObjectURL(e.target.files?.[0]),
        field: field,
      });

      setimagesToEdit(imagesToEdit.filter((image) => image.field !== field));
      setimagesToEdit((val) => [...val, newImage]);
      setStore({ ...Store, [field]: newImage.preview });
    }
  };

  return (
    <div className=" mx-2 my-4">
      <Card className="w-full">
        <CardHeader></CardHeader>
        <CardContent>
          <SaveChangesButton
            btnlabel={t("btns.save")}
            isPending={updateStoreMutation.isPending || isLoading}
            action={() => updateStoreMutation.mutate()}
            label={t("Settings.inside.store.cn")}
          />
          <div className="grid gap-6 md:grid-cols-2">
            {[
              {
                id: "store-storeAdOne",
                label: t("Settings.inside.store.adOne"),
                value: Store?.storeAdOne,
              },
              {
                id: "store-storeAdTwo",
                label: t("Settings.inside.store.adTwo"),
                value: Store?.storeAdTwo,
              },
              {
                id: "store-storeAdThree",
                label: t("Settings.inside.store.adThree"),
                value: Store?.storeAdThree,
              },
            ].map((field) => (
              <div key={field.id} className="space-y-2">
                <Label htmlFor={field.id}>{field.label}</Label>
                <Input
                  id={field.id}
                  value={field.value}
                  onChange={(e) =>
                    setStore({
                      ...Store,
                      [field.id.replace("store-", "")]: e.target.value,
                    })
                  }
                />
              </div>
            ))}
            {[
              {
                field: "sliderOne",
                label: t("Settings.inside.store.slider1"),
              },
              {
                field: "sliderTwo",
                label: t("Settings.inside.store.slider2"),
              },
              {
                field: "sliderThree",
                label: t("Settings.inside.store.slider3"),
              },
              { field: "logo", label: t("Settings.inside.store.logo") },
              { field: "icon", label: t("Settings.inside.store.icon") },
            ].map((field, index) => (
              <div key={index} className="space-y-2">
                <Label htmlFor={`store-${field.field}`}>{field.label}</Label>
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor={`store-${field.field}`}
                      className="cursor-pointer">
                      <div className="flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none hover:border-gray-400 focus:outline-none">
                        <span className="flex items-center space-x-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-gray-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                          </svg>
                          <span className="font-medium text-gray-600">
                            {Store[field.field] ? t("btns.RE") : t("btns.up")}{" "}
                            {field.label}
                          </span>
                        </span>
                      </div>
                      <Input
                        id={`store-${field.field}`}
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, field.field)}
                      />
                    </label>
                  </div>
                  {Store[field.field] && (
                    <div className="relative w-32 h-32">
                      <img
                        src={Store[field.field]}
                        alt={`${field.label} preview`}
                        className="w-full h-full object-contain rounded-md"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
