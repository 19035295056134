import  { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";


import SaveChangesButton from "@/AppComponents/SaveChangesButton";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { toast } from "@/components/ui/use-toast";

import { fetchStore, updateStore, uploadUrl } from "@/utils/StoreUtils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";


export default function Contact() {
  const [Store, setStore] = useState({});
  const { t } = useTranslation();
  const [imagesToEdit, setimagesToEdit] = useState([]);
  const { data: store, isLoading } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });
  useEffect(() => {
    if (store) {
      setStore(store?.data);
    }
  }, [store]);
  const queryClient = useQueryClient();
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store, imagesToEdit),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  
  

  
  return (
    <div className=" mx-2 my-4">
      <Card className="w-full">
        <CardHeader>
          
        </CardHeader>
        <CardContent>
          <SaveChangesButton
            btnlabel={t("btns.save")}
            isPending={updateStoreMutation.isPending || isLoading}
            action={() => updateStoreMutation.mutate()}
            label={t("Settings.inside.store.cn")}
          />
          <div className="grid gap-6 md:grid-cols-2">
                         {[
                           {
                             id: "store-Phone_number",
                             label: t("Settings.inside.store.pn"),
                             value: Store?.Phone_number,
                           },
                           {
                             id: "store-Facebook_link",
                             label: t("Settings.inside.store.fp"),
                             value: Store?.Facebook_link,
                           },
                           {
                             id: "store-Instagram_link",
                             label: t("Settings.inside.store.i"),
                             value: Store?.Instagram_link,
                           },
                         ].map((field) => (
                           <div key={field.id} className="space-y-2">
                             <Label htmlFor={field.id}>{field.label}</Label>
                             <Input
                               id={field.id}
                               value={field.value}
                               onChange={(e) =>
                                 setStore({
                                   ...Store,
                                   [field.id.replace("store-", "")]: e.target.value,
                                 })
                               }
                             />
                           </div>
                         ))}
                       </div>
        </CardContent>
      </Card>
    </div>
  );
}
