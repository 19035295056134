import axios from "axios";

const Axios = axios.create({

    //baseURL: "http://localhost:3001/",
    baseURL: "https://keeprify.com/backend", // uncommnet this in prod
    withCredentials: true,
});

export default Axios;
